import React from "react";
import Container from "../Container";
import DarkButton from "../buttons/DarkButton";
import openCalendlyPopup from "../../data/calendly";
import Heading from "../text/Heading";

export default function ConversationCTA() {
    return (
        <Container>
            <section className="bg-gradient-to-br from-primary-400 to-primary-800 text-white-100 py-10 xs:py-20 px-10 rounded-2xl translate-y-1/2">
                <Heading>All great things started with a conversation</Heading>
                <p className="mb-10">
                    Set up a free meeting with me and let's begin your new
                    project together.
                </p>
                <DarkButton onClick={openCalendlyPopup}>
                    Set up a meeting
                </DarkButton>
            </section>
        </Container>
    );
}
