import React from "react";
import FadeUp from "../animations/FadeUp";

export default function BenefitImageCard({
    direction = "row",
    title,
    text,
    imgSrc,
    imgAlt,
    children,
    className,
}) {
    const directions = {
        row: "flex-col-reverse sm:flex-row",
        col: "flex-col",
    };

    return (
        <FadeUp className={`p-5 bg-black-100 rounded-2xl ${className}`}>
            <p className="text-2xl font-bold text-primary-50 mb-6">{title}</p>
            <div className={`flex gap-4 ${directions[direction]}`}>
                <div className="basis-0 grow">
                    {children}
                </div>
                <div className="basis-0 grow sm:flex sm:justify-end">
                    <img
                        src={imgSrc}
                        alt={imgAlt || title}
                        className="rounded-xl object-cover max-h-96"
                    />
                </div>
            </div>
        </FadeUp>
    );
}
