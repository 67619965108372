import React from "react";
import Paragraph from "../text/Paragraph";

export default function BenefitTextDarkCard({
    title,
    icon,
    children,
    size = "normal",
}) {
    const sizes = {
        normal: "basis-0",
        large: "basis-full",
    };

    return (
        <div
            className={`${sizes[size]} grow bg-gradient-to-tr from-black-400 to-black-200 shadow-2xl p-5 rounded-2xl min-w-64 relative`}
        >
            <div className="bg-primary-50 rounded-2xl w-12 h-12 flex items-center justify-center absolute -top-6">
                {icon}
            </div>

            <h3 className="text-2xl my-6 font-semibold">{title}</h3>
            {children}
        </div>
    );
}
