import React from "react";
import Page from "./Page";
import MyStory from "../sections/MyStory";
import HowItStarted from "../sections/HowItStarted";
import FreelancerJourney from "../sections/FreelancerJourney";
import Container from "../Container";
import Paragraph from "../text/Paragraph";

export default function About() {
    const fiverrProfileLink = "https://www.fiverr.com/fvrhassan";

    return (
        <Page title="About Me - Hassandev">
            <MyStory />
            <HowItStarted />

            <div className="pt-20">
                <Container>
                    <div>
                        <div className="max-w-96 bg-white-200 rounded-xl p-5">
                            <img src="./images/fiverr-gig.png" alt="" />
                            <h4 className="my-4 text-xl font-semibold">
                                My Freelancer Journey
                            </h4>

                            <Paragraph>
                                Around January 2020, I became a freelancer on
                                Fiverr. I started working with clients, and
                                before long, I was getting steady work from a
                                variety of clients.
                            </Paragraph>

                            <Paragraph>
                                I later quit Fiverr and decided to do my own
                                thing. And that's when Hassandev was born.
                            </Paragraph>

                            <Paragraph className="text-black-100">
                                P.S: My{" "}
                                <a
                                    href={fiverrProfileLink}
                                    className="underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    old profile
                                </a>{" "}
                                is still up, in case you want to check it out.
                            </Paragraph>
                        </div>
                    </div>
                </Container>
            </div>
            {/* <FreelancerJourney /> */}
        </Page>
    );
}
