const projects = [
    {
        title: "BRICS - UI design",
        img: "./images/portfolio/brics.png",
        slug: "brics",
        description: "A landing page design I built, for a construction firm.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/brics",
        live: "https://brics-nine.vercel.app/",
        colspan: "col-span-2",
    },
    {
        title: "Roami",
        img: "./images/portfolio/roami.png",
        slug: "roami",
        description:
        "Image sharing social media platform built with React and Django REST.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/roami",
        live: "https://roami.vercel.app",
        colspan: "col-span-2",
    },
    {
        title: "Wanderly",
        img: "./images/portfolio/wanderly.png",
        slug: "wanderly",
        description: "A landing page I built for a travel agency.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/wanderly",
        live: "https://wanderly-eight.vercel.app/",
        colspan: "col-span-2",
    },
    {
        title: "Tailwind Gradients",
        img: "./images/portfolio/tailwindgradients.png",
        slug: "tailwind-gradients",
        description:
            "A gradient generator for Tailwind CSS. Easily generate a gradient from your own colors, or use Tailwind's predefined colors.",
        type: "personal",
        github: "https://github.com/hassanaziz0012/tailwindgradientgenerator",
        live: "https://tailwindgradients.vercel.app",
        tags: ["React", "NextJS", "Tailwind", "Vercel"],
    },
    {
        title: "Pigs Can Fly Labs",
        img: "./images/projects/pigscanfly.png",
        slug: "pigs-can-fly",
        description:
            "Niche e-commerce website built with Django and Stripe.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/pigscanfly",
        live: "https://www.pigscanfly.ca/",
        colspan: "col-span-2",
    },
    {
        title: "Anastasia Ederer",
        img: "./images/projects/anastasiaederer.jpg",
        slug: "anastasia-ederer",
        description:
            "Event management and booking website I built for my university staff to easily manage campus events.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/anastasia_portfolio",
        live: null,
        colspan: "col-span-1",
    },
    {
        title: "Lessons with a native",
        img: "./images/projects/lwan.png",
        slug: "lessons-with-a-native",
        description:
            "Learning platform built for a client as an internal app to manage student profiles, grades, tests, and other learning materials.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/lessons-with-a-native",
        live: "http://lessons-with-a-native.vercel.app/",
        colspan: "col-span-1",
    },
    {
        title: "Spotify Downloader",
        img: "./images/projects/spotifydownloader.gif",
        slug: "spotify-downloader",
        description:
            "Event management and booking website I built for my university staff to easily manage campus events.",
        type: "freelance",
        github: "https://github.com/hassanaziz0012/spotify-downloader",
        live: "https://github.com/hassanaziz0012/spotify-downloader?tab=readme-ov-file#installation",
        colspan: "col-span-1",
    },
];

export default projects;
