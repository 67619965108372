import React from "react";
import Container from "../Container";
import Paragraph from "../text/Paragraph";
import Quote from "../Quote";

export default function HowItStarted() {
    return (
        <section className="py-20 bg-white-200">
            <Container>
                <h2 className="text-2xl mb-6 font-bold">How it all started</h2>

                <div className="flex flex-col md:flex-row gap-12">
                    <div className="basis-0 grow">
                        <Paragraph>
                            My journey into web development began in January
                            2017, when I was a curious 12-year-old with a
                            computer and a passion for video games. When I
                            wasn't buried in schoolwork, I was deep in games
                            like Warcraft, exploring their immersive fantasy
                            worlds. Occasionally, I'd get ideas for features I
                            wished the developers would add. That sparked a
                            thought: what if I could do it myself?
                        </Paragraph>

                        <Paragraph>
                            I started researching coding and stumbled across
                            basic languages like HTML and CSS. This was my first
                            step into the world of programming. I began learning
                            HTML through the SoloLearn app, mistakenly thinking
                            it was a language used in game development. I had no
                            clue what I was doing at first, but I was eager to
                            learn and quickly found my footing.
                        </Paragraph>

                        <Paragraph>
                            Fast forward a couple of years, and I had become
                            proficient in web development, with Python and Rust
                            as my go-to languages. Although my initial interest
                            was in video games, I soon discovered a deep passion
                            for web development and everything that goes into
                            creating a great website.
                        </Paragraph>

                        <Paragraph>
                            What excites me most about web development is seeing
                            how all the different elements come together to
                            create something impactful. There's so much to
                            consider— design, performance, user experience,
                            content, copywriting, functionality, branding, and
                            more . It's a dynamic and ever-evolving field, and I
                            feel incredibly fortunate to be a part of it.
                        </Paragraph>
                    </div>

                    <div className="basis-0 grow">
                        <Quote breakpoint={"md"}>
                            <p className="text-3xl italic leading-normal">
                                What excites me most about web development is
                                seeing how all the different elements come
                                together to create something impactful. There's
                                so much to consider— design, performance, user
                                experience, content, copywriting, functionality,
                                branding, and more.
                            </p>
                        </Quote>
                    </div>
                </div>
            </Container>
        </section>
    );
}
