import React from "react";
import Container from "../Container";
import PrimaryButton from "../buttons/PrimaryButton";
import Button from "../buttons/Button";
import Paragraph from "../text/Paragraph";
import { useNavigate } from "react-router";
import openCalendlyPopup from "../../data/calendly";

export default function MyStory() {
    const navigate = useNavigate();
    return (
        <section className="bg-gradient-to-br from-black-600 to-black-300 text-white-200 py-20">
            <Container>
                <div className="max-w-screen-md">
                    <h1 className="text-6xl font-bold mb-6">I'm Hassan</h1>
                    <h2 className="text-2xl mb-4">
                        I'm the{" "}
                        <span className="text-primary-200">founder</span>.{" "}
                    </h2>

                    <Paragraph>
                        I'm a self-taught developer with over five years of
                        coding experience. Throughout my journey, I've crafted a
                        variety of projects—from websites and automation scripts
                        to Discord bots, APIs, and desktop and mobile apps.
                    </Paragraph>

                    <Paragraph>
                        Right now, I'm working as a freelance developer. I'm
                        always on the lookout for exciting new projects, so if
                        you have something interesting in mind, I'd love to hear
                        about it. I'm also open to full-time opportunities.
                    </Paragraph>

                    <div className="flex flex-wrap gap-x-12 gap-y-6 items-center justify-between mt-12">
                        <PrimaryButton onClick={openCalendlyPopup}>
                            Contact Me
                        </PrimaryButton>
                        <a
                            href="/resume.pdf"
                            className="py-2 border-b text-white-100 border-primary-500 hover:text-primary-500 duration-300 flex items-center justify-center fill-white-100 gap-x-2 hover:fill-primary-500"
                        >
                            Download Resume
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 -960 960 960"
                            >
                                <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58zM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </Container>
        </section>
    );
}
