import React from "react";
import Container from "../Container";
import StepperItem from "../layouts/StepperItem";
import Stepper from "../layouts/Stepper";
import FadeUp from "../animations/FadeUp";
import Heading from "../text/Heading";

export default function HowItWorks() {
    return (
        <section className="bg-white-100 pt-20">
            <FadeUp>
                <Container>
                    <h3 className="uppercase text-primary-600 font-bold mb-4">
                        How it works
                    </h3>
                    <Heading>3 simple steps to begin your new website</Heading>

                    <div className="flex flex-col justify-between mt-20 max-w-screen-md">
                        <Stepper>
                            <StepperItem num={1}>
                                Hop on a free zoom call and let's discuss your
                                project together. We'll also figure out the cost
                                and timeline.
                            </StepperItem>

                            <StepperItem num={2}>
                                I start working on your project, providing
                                regular updates and keeping you involved in the
                                development process.
                            </StepperItem>

                            <StepperItem num={3} join={"before"}>
                                I deliver the finished product to you. I also
                                help in deployment, and include 1 month free
                                maintenance in your website.
                            </StepperItem>
                        </Stepper>
                    </div>
                </Container>
            </FadeUp>
        </section>
    );
}
