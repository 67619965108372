import React, { useEffect } from "react";
import Container from "../Container";
import TestimonialCard from "../TestimonialCard";
import Masonry from "../layouts/Masonry";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    A11y,
    Autoplay,
    EffectCards,
    Navigation,
    Pagination,
    Parallax,
    Scrollbar,
} from "swiper/modules";
import testimonials from "../../data/testimonials";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/parallax";
import "swiper/css/effect-cards";
import FadeUp from "../animations/FadeUp";
import Heading from "../text/Heading";

export default function Testimonials() {
    function renderChunk(chunk) {
        return (
            <div className="flex flex-col gap-6">
                {chunk.map((e) => (
                    // <p className="text-4xl">Hello world!</p>
                    <TestimonialCard {...e} />
                ))}
            </div>
        );
    }

    function renderMasonry(array) {
        const chunkSize = 2;
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            chunks.push(chunk);
        }
        console.log(chunks);

        const cols = {
            1: "grid-cols-1",
            2: "grid-cols-2",
            3: "grid-cols-3",
            4: "grid-cols-4",
        };

        return (
            <div className={`grid gap-6 ${cols[chunks.length]}`}>
                {chunks.map((chunk) => renderChunk(chunk))}
            </div>
        );
    }

    return (
        <section className="bg-white-100 my-40">
            <FadeUp>
                <Container>
                    <p className="uppercase text-primary-600 font-bold mb-4">
                        People saying nice things
                    </p>
                    <Heading>What other small businesses say about me</Heading>

                    <div className="md:hidden">
                        <Masonry
                            chunkSize={9}
                            items={testimonials}
                            renderElem={(t, i) => (
                                <TestimonialCard {...t} key={i} />
                            )}
                        />
                    </div>

                    <div className="hidden md:block lg:hidden">
                        <Masonry
                            chunkSize={5}
                            items={testimonials}
                            renderElem={(t, i) => (
                                <TestimonialCard {...t} key={i} />
                            )}
                        />
                    </div>

                    <div className="hidden lg:block">
                        <Masonry
                            chunkSize={3}
                            items={testimonials}
                            renderElem={(t, i) => (
                                <TestimonialCard {...t} key={i} />
                            )}
                        />
                    </div>

                    {/* <div className="">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        modules={[Navigation, Pagination, A11y, Parallax, EffectCards, Autoplay]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true
                        }}
                        autoHeight={true}
                        pagination={{ clickable: true, dynamicBullets: true }}
                        navigation={{
                            prevEl: ".prev-btn",
                            nextEl: ".next-btn"
                        }}
                        // effect="cards"
                        loop={true}
                        centeredSlides={true}
                        grabCursor={true}
                    >
                        {testimonials.map((t) => (
                            <SwiperSlide>
                                <div className="flex justify-center mb-10">
                                    <TestimonialCard {...t} />
                                </div>
                            </SwiperSlide>
                        ))}
                        <div className="w-8 h-8 flex items-center justify-center text-white-100 rounded-full bg-primary-500 prev-btn absolute bottom-0 left-0 z-20">
                            {"<"}
                        </div>
                        <div className="w-8 h-8 flex items-center justify-center text-white-100 rounded-full bg-primary-500 next-btn absolute bottom-0 right-0 z-20">
                            {">"}
                        </div>
                    </Swiper>
                </div> */}

                    {/* <Masonry
                    items={testimonials}
                    chunkSize={3}
                    renderElem={(t, i) => <TestimonialCard {...t} key={i} />}
                /> */}
                </Container>
            </FadeUp>
        </section>
    );
}
